import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom'

import Nav from "./Nav";
import {LoginDialog} from "../React/User/Dialog";

import "./../SCSS/Log.scss";

import {signIn, signUp} from "./../../Controller/LogContoller";
import {getNumUsers} from "./../../Controller/UsersController";


const Log = () => {
    let [logOption, setLogOption] = useState(true);
    let [signedUser, setSignedUser] = useState(localStorage.getItem("user"));
    let [signedAdmin, setSignedAdmin] = useState(localStorage.getItem("admin"));
    let [nombre, setNombre] = useState("");
    let [apellidos, setApellidos] = useState("");
    let [email, setEmail] = useState("");
    let [telefono, setTelefono] = useState("");
    let [password, setPassword] = useState("");
    let [confirmPassword, setConfirmPassword] = useState("");
    let [statusProcess, setStatusProcess] = useState(0);
    let [showDialog, setShowDialog] = useState(false);
    let [dialog, setDialog] = useState([])

    const changeOption = () => setLogOption(!logOption);

    useEffect(() => {
        getNumUsers()
        .then(res=>console.log(res))
        .catch(err => console.log(err))
    }, []);

    const logIn = (e) => {
        e.preventDefault()
        setDialog({
            "title" : "Iniciando Sesión",
            "text" : "Esto tomará unos segundo",
            "icon" : "loading"
        })
        setShowDialog(true)
        var re = /\S+@\S+\.\S+/;
        let isEmail = re.test(email);
        if (email.length > 0 && password.length > 0 ){
            if(isEmail) {
                //SIGN IGN
                signIn(email, password)
                .then(response => {
                    if(response.error) {
                        //alert("El usuario no existe")
                        setDialog({
                            "title": "Ups!",
                            "text": "Este email no está registrado",
                            "icon": "error"
                        })
                        setTimeout(() => {
                            setShowDialog(false)
                        }, 1500)
                    } else {
                        if(response.status) {
                            //alert("éxito!")
                            setDialog({
                                "title" : "Éxito!",
                                "text" : "Sesion iniciada con éxito",
                                "icon" : "success"
                            })
                            setTimeout(() => {
                                setShowDialog(false)
                                console.log(response.user)
                                if (response.user.role === "admin") {
                                    setSignedAdmin(true)
                                } else if (response.user.role === "user") {
                                    setSignedUser(true)
                                }
                            }, 1000)
                            
                            //alert("usuairo es" + response.user.role)
                        } else {
                            setDialog({
                                "title": "Ups!",
                                "text": "Tu email o contraseña son incorrectos",
                                "icon": "error"
                            })
                            setTimeout(() => {
                                setShowDialog(false)
                            }, 1500)
                            //alert("usuario o contraseña incorrectos")
                        }
                    }
                })
                .catch(error => {
                    alert(error)
                    setShowDialog(false)
                })
            } else {
                alert("Introduce una direccion de correo válida")
                setShowDialog(false)
            }
        } else {
            alert("Favor de llenar todos los campos")
            setShowDialog(false)
        }
    }

    const register = (e) => {
        e.preventDefault()
        setDialog({
            "title" : "Registrando",
            "text"  : "Esto tomará un segundo",
            "icon"  : "loading"
        })
        setShowDialog(true)
        var re = /\S+@\S+\.\S+/;
        let isEmail = re.test(email);
        
            if (email.length > 0 && nombre.length > 0 && apellidos.length > 0 && telefono.length > 0 && password.length > 0 && confirmPassword.length > 0) {
                if(isEmail) {
                    if (password === confirmPassword) {
                        //SIGN UP
                        signUp(nombre, apellidos, email, password, telefono)
                        .then(response => {
                            if(response.status === false) {
                                //alert("Upps! Este email ya está registrado")
                                setDialog({
                                    "title": "Ups!",
                                    "text": "Este email ya está registrado",
                                    "icon": "error"
                                })
                                setTimeout(()=> {
                                    setShowDialog(false)
                                }, 2000)
                            } else {
                                setDialog({
                                    "title": "Listo!",
                                    "text": "Ahora inicia sesión",
                                    "icon": "success"
                                })
                                setTimeout(()=> {
                                    setShowDialog(false)
                                }, 1500)
                                //alert("Listo! Ahora inicia sesión para continuar")
                                changeOption()
                            }
                        })
                        .catch(error=> {
                            console.log(error)
                            alert("Ups! Ocurrió un error, inténtalo de nuevo más tarde")
                            setShowDialog(false)
                        })
                    } else {
                        alert("Las contraseñas no coinciden")
                        setShowDialog(false)
                    }
                } else {
                    alert("Introduce una direccion de correo válida")
                    setShowDialog(false)
                }
            } else {
                alert("Favor de llenar todos los campos")
                setShowDialog(false)
            }
    }

        if(signedUser){
            return(
                <Redirect to="/user/dashboard" />
            )
        } 
        else if (signedAdmin) {
            return(
                <Redirect to="/admin/dashboard" />
            )
        }
        else {
            return (
                <main className="globalContainer">

                    <Nav />

                    <section className="main" style={{ flexDirection: logOption ? "row" : "row" }} >

                        {
                            logOption ?
                                (
                                    <div className="select-log-option">
                                        <span>¿No tienes cuenta?</span>
                                        <button onClick={changeOption}>Crear cuenta</button>
                                        <span className="citysoft">Desarrollado por <a target="_blank" href="https://citysoftware.dev">{"{CS}"}</a></span>
                                    </div>
                                ) : (
                                    <div className="select-log-option">
                                        <span>¿Ya tienes cuenta?</span>
                                        <button onClick={changeOption}>Iniciar Sesión</button>
                                        <span className="citysoft">Desarrollado por <a target="_blank" href="https://citysoftware.dev">{"{CS}"}</a></span>
                                    </div>
                                )
                        }

                        {
                            logOption ?
                                (


                                    <div className="log-option">
                                        <span className="title">Iniciar Sesión</span>
                                        <span className="description">Inicia sesión para acceder a tu contenido</span>
                                        <form>
                                            <div className="inputRow row-100">
                                                <label>Email</label>
                                                <input type="email" placeholder="" value={email} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-100">
                                                <label>Contraseña</label>
                                                <input type="password" placeholder="" value={password} onChange={e => setPassword(e.target.value)} maxLength="16" />
                                            </div>
                                            <button onClick={logIn} >Entrar</button>
                                        </form>

                                    </div>


                                ) : (
                                    <div className="log-option">
                                        <span className="title">Registrarse</span>
                                        <span className="description">Regístrate para acceder a contenido exclusivo</span>
                                        <form>
                                            <div className="inputRow row-50">
                                                <label>Nombre</label>
                                                <input type="text" placeholder="" value={nombre} onChange={e => setNombre(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Apellidos</label>
                                                <input type="text" placeholder="" value={apellidos} onChange={e => setApellidos(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Email</label>
                                                <input type="email" placeholder="" value={email} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Celular</label>
                                                <input type="tel" placeholder="" value={telefono} onChange={e => setTelefono(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Contraseña</label>
                                                <input type="password" placeholder="" value={password} onChange={e => setPassword(e.target.value)} maxLength="16" />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Confirmar contraseña</label>
                                                <input type="password" placeholder="" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} maxLength="16" />
                                            </div>
                                            {statusProcess === 0 ? <button onClick={register}>Registrarme</button> : statusProcess === 1 ? <button>Guardando...</button> : statusProcess === 2 ? <button>Listo!!</button> : statusProcess === 4 ? <button>Hubo un error :(</button> : null}
                                        </form>

                                    </div>
                                )
                        }

                    

                    </section>

                    {
                        showDialog ? (
                            <LoginDialog title={dialog.title} text={dialog.text} icon={dialog.icon} />
                        ) : null
                    }

                </main>
            )
        }

}

export default Log;