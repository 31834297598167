import React, {useState } from 'react';
import TopBar from '../TopBar';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

import { newCourse } from "./../../../Controller/CoursesController";

import goBackIcon from "./../../../assets/icons/goBack.svg";


import "./Styles/AdminEditCourse.scss";

const AdminNewCourse = () => {

    //const [course, setCourse] = useState({name:"", URLCourse:"", description:"", duration:"", price:"", photo:"", topics:""});
    let [counter, setCounter] = useState(false);
    let [status, setStatus] = useState(1);

    const [name, setName] = useState("")
    const [URLCourse, setURLCourse] = useState("")
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState("")
    const [price, setPrice] = useState("")
    const [photo, setPhoto] = useState("")
    const [category, setCategory] = useState("")
    const [topics, setTopics] = useState([])


    const addTopicCard = () => {
        let tops = topics;
        tops.push({})
        setTopics(tops)
        setCounter(!counter)
    }

    const updateTopic = (index, mode, value) => {
        let tops = topics;
        if (mode === "title") {
            tops[index].title = value;
        } else {
            tops[index].description = value;
        }
        setTopics(tops)
        setCounter(!counter)
    }

    const deleteTopic = (index) => {
        let tops = topics;
        topics.splice(index, 1)
        setTopics(tops)
        setCounter(counter++)
    }

    const save = () => {
        setStatus(2)
        let course = {
            "name": name,
            "price": price,
            "description": description,
            "duration": duration,
            "URLcourse": URLCourse,
            "photo": photo,
            "category": category,
            "status": true,
            "topics": topics
        }
        newCourse(course)
            .then(res => {
                console.log(res)
                setStatus(3)
            })
            .catch(error => {
                console.log(error)
                setStatus(4)
            })

    }


    return (
        <div className="adminEditCourse">
            <TopBar title="Nuevo curso" />
            <section className="content">
                <Sidebar selected={2} />
                <main>

                    <div>
                        <Link to="/admin/courses" className="goback" > <img alt="regresar" src={goBackIcon} /> Regresar</Link>
                    </div>
                    <h1 className="screen-title">Nuevo curso</h1>




                            <div className="container container-course-info">

                                <section className="section">
                                    <h2 className="section-title">Informacion General</h2>
                                    <div className="section-content">
                                        <div className="inputRowCourse course-row-50">
                                            <label>Nombre del curso</label>
                                            <input type="text" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                                        </div>

                                        <div className="inputRowCourse course-row-50">
                                            <label>Precio</label>
                                            <input type="text" placeholder="ej. 90.00" value={price} onChange={e => setPrice(e.target.value)} />
                                        </div>

                                        <div className="inputRowCourse course-row-50">
                                            <label>Duración</label>
                                            <input type="text" placeholder="ej. 2hrs" value={duration} onChange={e => setDuration(e.target.value)} />
                                        </div>
                                        <div className="inputRowCourse course-row-50">
                                            <label>Categoría</label>
                                            <input type="text" placeholder="ej. Negocios" value={category} onChange={e => setCategory(e.target.value)} />
                                        </div>
                                    </div>
                                </section>

                                <section className="section">
                                    <h2 className="section-title">Detalles</h2>
                                    <div className="section-content">
                                        <div className="inputRowCourse course-row-100">
                                            <label>Descripción del curso</label>
                                            <input type="text" placeholder="" value={description} onChange={e => setDescription(e.target.value)} />
                                        </div>

                                        <div className="contenido-tematico inputRowCourse course-row-100">
                                            <label>Contenido Temático</label>
                                            <div className="button-container">
                                                <button onClick={addTopicCard}>Agregar tarjeta</button>
                                            </div>
                                            <div className="topics">
                                                {
                                                    topics ? (
                                                        topics.length > 0 ? (
                                                            topics.map((topic, index) =>
                                                                <div className="topic-card" key={index}>
                                                                    <span>Nombre del tema:</span>
                                                                    <input type="text" value={topic.title} onChange={e => updateTopic(index, "title", e.target.value)} ></input>
                                                                    <span>Descripción:</span>
                                                                    <input type="text" value={topic.description} onChange={e => updateTopic(index, "description", e.target.value)} ></input>
                                                                    <div className="buttons">
                                                                        <button onClick={() => deleteTopic(index)}>Eliminar</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ) : <p>No hay contendidos temáticos</p>
                                                    ) : <p>No hay contendidos temáticos</p>
                                                }
                                            </div>


                                        </div>

                                        <div className="inputRowCourse course-row-100">
                                            <label>URL del video</label>
                                            <input type="text" placeholder="" value={URLCourse} onChange={e => setURLCourse(e.target.value)} />
                                        </div>

                                        <div className="inputRowCourse course-row-100">
                                            <label>Imagen de curso</label>
                                            <input type="text" placeholder="ej. https://www.loremipsum.com/buih346798034" value={photo} onChange={e => setPhoto(e.target.value)} />
                                        </div>

                                    </div>
                                </section>

                                <button onClick={save} className="btn-actualizar-curso"> {status === 1 ? "Guardar" : status === 2 ? "Guardando" : status === 3 ? "Listo!" : status === 4 ? "Error :(" : null} </button>

                            </div>

                </main>
            </section>
        </div>
    )
}

export default AdminNewCourse;