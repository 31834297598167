import React, {useEffect, useState, useRef} from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import { Redirect, Link } from 'react-router-dom';
import TopBar from '../TopBar';
import Sidebar from './Sidebar';
import goBackIcon from "./../../../assets/icons/goBack.svg";
//import oxxoPayButton from "./../../../assets/img/oxxoPayButton.png";
import oxxoPayButton from "./../../../assets/icons/oxxoPayButton.svg";

import "./Styles/UserPay.scss";
import { SuccessDialog } from './Dialog';
import {LoginDialog, OxxoDialog, ShowReference} from "./../User/Dialog";
import { enrollTo } from "./../../../Controller/UsersController";
import { createOxxoReference} from "./../../../Controller/PaymentConektaController";



const UserPay = () => {

    const paypalRef = useRef();

    let [user, setUser] = useState([])
    let [course, setCourse] = useState([])
    let [redirectSuccess, setRedirectSuccess] = useState(false)

    let [showDialog, setShowDialog] = useState(false);
    let [dialog, setDialog] = useState([])

    let [showOxxoDialog, setShowOxxoDialog] = useState(false);
    let [oxxoDialog, setOxxoDialog] = useState([]);
    let [oxxoReference, setOxxoReference] = useState([]);
    let [showReference, setShowReference] = useState(false)

    useEffect(()=> {
        setUser(JSON.parse(localStorage.getItem("user")))
        setCourse(JSON.parse(sessionStorage.getItem("courseIntent")))
    }, []);

    const enroll = () => {
        setDialog({
            "title" : "Procesando",
            "text" : "Te estamos enrolando en el curso",
            "icon" : "loading"
        })
        setShowDialog(true)
        let user = JSON.parse(localStorage.getItem("user"));
        let newUser = user;
        if(newUser.courses) {
        newUser.courses[course.id] = {
            "idCourse": course.id,
            "status": true,
            "name": course.name,
            "photoCourse": course.photo
        }
        let data = {
            "id" : user.id,
            "newEnrolledCourse" : {
                "idCourse": course.id,
                "status": true,
                "name": course.name,
                "photoCourse": course.photo
            }
        }
        enrollTo(data)
            .then(res => {
                console.log(res)
                localStorage.setItem("user", JSON.stringify(newUser))
                setDialog({
                    "title": "Listo!",
                    "text": "Te estamos enrolando en el curso",
                    "icon": "loading"
                })
                setShowDialog(true)
                setRedirectSuccess(true)
            })
            .catch(err => console.log(err))
        }
        else {
            newUser.courses = {}
            newUser.courses[course.id] = {
                "idCourse": course.id,
                "status": true,
                "name": course.name,
                "photoCourse": course.photo
            }
            let data = {
                "id": user.id,
                "newEnrolledCourse": {
                    "idCourse": course.id,
                    "status": true,
                    "name": course.name,
                    "photoCourse": course.photo
                }
            }
            enrollTo(data)
                .then(res => {
                    console.log(res)
                    localStorage.setItem("user", JSON.stringify(newUser))
                    setRedirectSuccess(true)
                })
                .catch(err => console.log(err))
        }
    }

    const generateOxxoReference = () => {
        // Set loading dialog
        setOxxoDialog({
            title: "Generando",
            text: "Por favor espera un momento",
            icon: "loading",
            action : {
                type : "",
                handler : ""
            }
        });
        let order = {
            "course": {
                "id": course.id,
                "name": course.name,
                "photo" : course.photo,
                "price": (Number(course.price) * 100)
            },
            "user": {
                "id"  : user.id,
                "name": user.name + " " + user.lastName,
                "email": user.email,
                "phone": user.phone
            }
        }
        createOxxoReference(order)
            .then(res => {
                if(res.status) {
                    setOxxoDialog({
                        title: "Listo!",
                        text: "Ahora imprime tu referencia y ve al OXXO más cercano",
                        icon: "sucess",
                        action: {
                            type: "print",
                            handler : "",
                            reference : res.response
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err)
            }) 
        
    }

    const closeOxxoDialog = () => setShowOxxoDialog(false)

    const oxxoPayment = () => {
        setOxxoDialog({
            title : "Oxxo Pay",
            text : "Genera una referencia para efectuar tu pago en en efectivo en Oxxo",
            action : {
                type : "generate",
                handler : generateOxxoReference
            }
        })
        setShowOxxoDialog(true)
    }


    if (localStorage.getItem("user") && sessionStorage.getItem("courseIntent") ) {
        return (
            <div className="payScreen">
                <TopBar title="Comprar curso" />
                <section className="content">
                    <Sidebar selected={0} />

                    <main>

                        <div>
                            <Link to="/user/dashboard" className="goback" > <img alt="regresar" src={goBackIcon} /> Regresar</Link>
                        </div>
                        <h1 className="screen-title">Checkout</h1>

                        <div className="container">

                            <div className="to-pay-info">
                                <span className="to-pay-info-title">Resumen de compra</span>
                                <div className="to-pay-info-course">

                                    <div className="presentation" style={{ backgroundImage: `url(${course.photo})` }} >
                                        <span>ESCUELA DE</span>
                                        <p>{course.category}</p>
                                    </div>
                                    <div className="info-course-name">
                                        <span>Curso:</span>
                                        <p>{course.name}</p>
                                    </div>
                                    <div className="info-course-price">
                                        <span>Precio:</span>
                                        <p>${course.price}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="to-pay-info">
                                <span className="to-pay-info-title">Tus datos</span>
                                <div className="to-pay-info-user">

                                    <div className="info-user-name">
                                        <span>Nombre:</span>
                                        <p>{user.name} {user.lastName}</p>
                                    </div>

                                    <div className="info-user-name">
                                        <span>Celular:</span>
                                        <p>{user.phone}</p>
                                    </div>

                                    <div className="info-user-name">
                                        <span>Email:</span>
                                        <p>{user.email}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="pay-buttons">
                                <span className="pay-buttons-title">Método de pago</span>
                                <div className="pay-buttons-payment">
                                    <button onClick={oxxoPayment} className="btn">
                                        <img src={oxxoPayButton} />
                                    </button>
                                </div>
                                <div className="pay-buttons-payment" >

                                    <PayPalButton
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [{
                                                    amount: {
                                                        currency_code: "MXN",
                                                        value: course.price
                                                    },
                                                    item_list: {
                                                        items: {
                                                            name: course.name,
                                                        }
                                                    }
                                                }],
                                                // application_context: {
                                                //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                                                // }
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            // Capture the funds from the transaction
                                            return actions.order.capture().then(function (details) {
                                                // Show a success message to your buyer
                                                console.log("Transaction completed by " + details.payer.name.given_name);
                                                enroll()
                                                //setRedirectSuccess(true)
                                                // OPTIONAL: Call your server to save the transaction
                                                /**
                                                 * return fetch("/paypal-transaction-complete", {
                                                    method: "post",
                                                    body: JSON.stringify({
                                                        orderID: data.orderID
                                                    })
                                                });
                                                 */

                                            });
                                        }}
                                    />

                                </div>
                            </div>

                        </div>

                    </main>

                </section>
                {
                    redirectSuccess ? (
                        <SuccessDialog url={"/user/takeCourse?course=" + course.id} />
                    ): null
                }
                {
                    showDialog ? (
                        <LoginDialog title={dialog.title} text={dialog.text} icon={dialog.icon} />
                    ) : null
                }
                {
                    showOxxoDialog ? (
                        <OxxoDialog close={closeOxxoDialog} title={oxxoDialog.title} text={oxxoDialog.text} icon={oxxoDialog.icon} action={oxxoDialog.action}  />
                    ) : null
                }

            </div>
        )
    } else {
        return(
            <Redirect to="/" />
        )
    }
}

export default UserPay;