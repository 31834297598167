import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import {getCourseById} from "./../../../Controller/CoursesController";

import "./Styles/ViewCourse.scss"
import TopBar from '../TopBar';
import Sidebar from './Sidebar';
import coemlelogo from "./../../../assets/coemle.png";
import money from "./../../../assets/icons/money.svg";
import clock from "./../../../assets/icons/clock.svg";
import goBackIcon from "./../../../assets/icons/goBack.svg";

const AdminViewCourse = () => {

    //STATE
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState("")
    const [category, setCategory] = useState("")
    const [price, setPrice] = useState("")
    const [photo, setPhoto] = useState("")
    const [topics, setTopics] = useState([])

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const idCourse = params.get('course');
        getCourseById(idCourse)
            .then(result => {
                setName(result.course.name)
                setDescription(result.course.description)
                setPrice(result.course.price)
                setDuration(result.course.duration)
                setCategory(result.course.category)
                setTopics(result.course.topics ? Object.values(result.course.topics) : [])
                setPhoto(result.course.photo)
            })
            .catch(error => console.log(error))
    }, []);

    return(
        <div className="adminViewCourse viewCourse">
            <TopBar title={name} />
            <section className="content">

                <Sidebar selected={2} />

                <main>

                    <div className="cover" style={{backgroundImage: `url(${photo})` }} >
                        <div className="cover-content">
                            <div className="cover-category">
                                <span>ESCUELA DE </span>
                                <span className="category">{category}</span>
                            </div>
                            <h1 className="cover-title">{name}</h1>
                        </div>
                        <img alt="" src={coemlelogo} />
                        <Link to="/admin/courses" className="goback" > <img alt="regresar" src={goBackIcon} /> Regresar</Link>
                    </div>

                    <div className="banner-general-info">
                        <div className="info">
                            <span className="info-title"> <img alt="" src={clock} />Duración</span>
                            <span className="info-content">{duration}</span>
                        </div>
                        <div className="info">
                            <span className="info-title"> <img alt="" src={money} />Precio</span>
                            <span className="info-content">{price}</span>
                        </div>
                    </div>

                    <div className="container">
                        <div className="course-content-info">
                            <section>
                                <h2>Lo que aprenderás</h2>
                                <p>{description}</p>
                            </section>
                            <section>
                                <h2>Contenido temático</h2>
                                <div className="topics">
                                    {
                                        topics ? (
                                            topics.map(topic =>
                                                <div className="topic" key={Math.random()}>
                                                    <h3>{topic.title}</h3>
                                                    <p>{topic.description}</p>
                                                </div>
                                            )
                                        ) : <p>Cargando...</p>
                                    }
                                </div>
                            </section>

                            <section className="goAction">

                            </section>

                        </div>
                    </div>
                        


                </main>

            </section>
        </div>
    )


}

export default AdminViewCourse;