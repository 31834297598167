import React, {useEffect, useState} from 'react';
import TopBar from '../TopBar';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

import { getCourseById, updateCourse } from "./../../../Controller/CoursesController";

import goBackIcon from "./../../../assets/icons/goBack.svg";


import "./Styles/AdminEditCourse.scss";
import Loading from '../Loading';

const AdminEditCourse = () => {

    //const [course, setCourse] = useState({name:"", URLCourse:"", description:"", duration:"", price:"", photo:"", topics:""});
    let [counter, setCounter] = useState(false);
    let [status, setStatus] = useState(1);

    const [name, setName] = useState("")
    const [URLCourse, setURLCourse] = useState("")
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState("")
    const [price, setPrice] = useState("")
    const [photo, setPhoto] = useState("")
    const [topics, setTopics] = useState([])
    const [category, setCategory] = useState("")
    const [id, setId] = useState("")

    useEffect( () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const idCourse = params.get('course');
        getCourseById(idCourse)
            .then(result => {
                setId(result.course.id)
                setName(result.course.name)
                setDescription(result.course.description)
                setPrice(result.course.price)
                setCategory(result.course.category)
                setDuration(result.course.duration)
                setTopics(result.course.topics ? Object.values(result.course.topics) : [])
                setURLCourse(result.course.URLcourse)
                setPhoto(result.course.photo)
            })
            .catch(error => console.log(error))
    },[]);

    const addTopicCard = () => {
        let tops = topics;
        tops.push({})
        setTopics(tops)
        setCounter(!counter)
    }

    const updateTopic = (index, mode, value) => {
        let tops = topics;
        if(mode === "title") {
            tops[index].title = value;
        } else {
            tops[index].description = value;
        }
        setTopics(tops)
        setCounter(!counter)
    }

    const deleteTopic = (index) => {
        let tops = topics;
        topics.splice(index, 1)
        setTopics(tops)
        setCounter(counter++)
    }

    const update = () => {
        setStatus(2)
        let course = {
            "id" : id,
            "name" : name,
            "price" : price,
            "description" : description,
            "duration" : duration,
            "URLcourse": URLCourse,
            "category" : category,
            "photo" : photo,
            "status" : true,
            "topics" : topics
        }
        updateCourse(course)
            .then (res => {
                console.log(res)
                setStatus(3)
            })
            .catch(error => {
                console.log(error)
                setStatus(4)
            })
        
    }


    return(
        <div className="adminEditCourse">
            <TopBar title="Editar curso" />
            <section className="content">
                <Sidebar selected={2} />
                <main>

                    <div>
                        <Link to="/admin/courses" className="goback" > <img alt="regresar" src={goBackIcon} /> Regresar</Link>
                    </div>
                    <h1 className="screen-title">Editar curso</h1>

                    

                        {
                            name ? (
                            <div className="container container-course-info">

                                <section className="section">
                                    <h2 className="section-title">Informacion General</h2>
                                    <div className="section-content">
                                        <div className="inputRowCourse course-row-50">
                                            <label>Nombre del curso</label>
                                            <input type="text" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                                        </div>

                                        <div className="inputRowCourse course-row-50">
                                            <label>Precio</label>
                                            <input type="text" placeholder="ej. 90.00" value={price} onChange={e => setPrice(e.target.value)} />
                                        </div>

                                        <div className="inputRowCourse course-row-50">
                                            <label>Duración</label>
                                            <input type="text" placeholder="ej. 2hrs" value={duration} onChange={e => setDuration(e.target.value)} />
                                        </div>

                                        <div className="inputRowCourse course-row-50">
                                            <label>Categoría</label>
                                            <input type="text" placeholder="ej. 2hrs" value={category} onChange={e => setCategory(e.target.value)} />
                                        </div>
                                    </div>
                                </section>

                                <section className="section">
                                    <h2 className="section-title">Detalles</h2>
                                    <div className="section-content">
                                        <div className="inputRowCourse course-row-100">
                                            <label>Descripción del curso</label>
                                            <input type="text" placeholder="" value={description} onChange={e => setDescription(e.target.value)} />
                                        </div>

                                        <div className="contenido-tematico inputRowCourse course-row-100">
                                            <label>Contenido Temático</label>
                                            <div className="button-container">
                                                <button onClick={addTopicCard}>Agregar tarjeta</button>
                                            </div>
                                            <div className="topics">
                                                {
                                                    topics ? (
                                                        topics.length > 0 ? (
                                                            topics.map((topic, index) =>
                                                                <div className="topic-card" key={index}>
                                                                    <span>Nombre del tema:</span>
                                                                    <input type="text" value={topic.title} onChange={e => updateTopic(index, "title", e.target.value)  } ></input>
                                                                    <span>Descripción:</span>
                                                                    <input type="text" value={topic.description} onChange={e => updateTopic(index, "description", e.target.value)} ></input>
                                                                    <div className="buttons">
                                                                        <button onClick={() => deleteTopic(index)}>Eliminar</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ) : <p>No hay contendidos temáticos</p>
                                                    ) : <p>No hay contendidos temáticos</p>
                                                }
                                            </div>


                                        </div>

                                        <div className="inputRowCourse course-row-100">
                                            <label>URL del video</label>
                                            <input type="text" placeholder="" value={URLCourse} onChange={e => setURLCourse(e.target.value)} />
                                        </div>

                                        <div className="inputRowCourse course-row-100">
                                            <label>Imagen de curso</label>
                                            <input type="text" placeholder="ej. https://www.loremipsum.com/buih346798034" value={photo} onChange={e => setPhoto(e.target.value)} />
                                        </div>

                                    </div>
                                </section>

                                <button  onClick={update} className="btn-actualizar-curso"> {status === 1 ? "Actualizar" : status === 2 ? "Guardando" : status === 3 ? "Listo!" : status === 4 ? "Error :(" : null } </button>

                            </div>
                        ) : <Loading />
                        }
                    

                </main>
            </section>
        </div>
    )
}

export default AdminEditCourse;