import React, {useState, useEffect} from 'react';
import ReactPlayer from 'react-player'
import { Redirect, Link } from 'react-router-dom';

import {getCourseById} from "./../../../Controller/CoursesController";
import TopBar from '../TopBar';
import Sidebar from './Sidebar';
import Loading from '../Loading';
import coemlelogo from "./../../../assets/coemle.png";
import goBackIcon from "./../../../assets/icons/goBack.svg";

import "./Styles/TakeCourse.scss";

const TakeCourse = () => {

    const [course, setCourse] = useState([])

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const idCourse = params.get('course');
        getCourseById(idCourse)
            .then(result => {
                setCourse(result.course)
            })
            .catch(error => console.log(error))

    }, []);


    if(localStorage.getItem("user")) {
        return (
            <div className="takeCourse">
                <TopBar title={course.name} />
                <section className="content">
                    <Sidebar selected={2} /> 

                    {
                        course.name ? (
                            <main>

                                <div className="cover" style={{ backgroundImage: `url(${course.photo})` }} >
                                    <div className="cover-content">
                                        <div className="cover-category">
                                            <span>ESCUELA DE </span>
                                            <span className="category">{course.category}</span>
                                        </div>
                                        <h1 className="cover-title">{course.name}</h1>
                                    </div>
                                    <img alt="" src={coemlelogo} />
                                    <Link to="/user/dashboard" className="goback" > <img alt="regresar" src={goBackIcon} /> Regresar</Link>
                                </div>

                                <div className="container">

                                    <div className="course-content-info">
                                        <section>
                                            <h2>Lo que aprenderás</h2>
                                            <p>{course.description}</p>
                                        </section>
                                    </div>

                                    <div className="course-video">
                                        <h2>Curso</h2>
                                        <video className="video" width="100%" height="500" controls controlsList="nodownload" >
                                            <source src={course.URLcourse} type="video/mp4" />
                                        </video>
                                        {/*<ReactPlayer config={{
                                            youtube: {
                                                playerVars: { showinfo: 1, iv_load_policy: 3, rel: 0, modestbranding : 1 }
                                            }
                                        }}  url={course.URLcourse} controls={true} className="video" style={{width:"100%", maxWidth:"100%", minWidth:"100%", outline : "none", appereance:"none", border:"0"}}  /> */}
                                    </div>

                                </div>

                            </main>
                        ) : <Loading />
                    }

                </section>
            </div>
        )
    } else {
        return(
            <Redirect to="/" />
        )
    }
}

export default TakeCourse;