import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";

import { updateUser } from "./../../../Controller/UsersController";

import "./../../SCSS/Profile.scss";
import Sidebar from './Sidebar';
import TopBar from '../TopBar';
import { LoadingDialog } from "./Dialog";
import Loading from '../Loading';

const AdminProfile = () => {

    const [showLoading, setShowLoading] = useState(false);
    const [status, setStatus] = useState(1);
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [photo, setPhoto] = useState("")
    const [email, setEmail] = useState("")
    const [courses, setCourses] = useState("")
    const [password, setPassword] = useState("")
    const [role, setRole] = useState("")


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("admin"))
        setId(user.id)
        setName(user.name)
        setLastName(user.lastName)
        setPhone(user.phone)
        setPhoto(user.photo)
        setEmail(user.email)
        setCourses(user.courses)
        setPassword(user.password)
        setRole(user.role)
        console.log(user)
    }, []);

    const close = () => {
        setShowLoading(false)
    }

    const changePhoto= () => {
        alert("changing")
    }

    const update = () => {
        var re = /\S+@\S+\.\S+/;
        let isEmail = re.test(email);
        if (name.length > 0 && lastName.length > 0 && photo.length > 0 && phone.length > 0 && email.length > 0 && password.length > 0){
            if(isEmail){
                setStatus(2)
                let user = {
                    "id": id,
                    "name": name,
                    "lastName": lastName,
                    "photo": photo,
                    "phone": phone,
                    "email": email,
                    "password": password,
                    "status": true,
                    "role": role,
                    "courses": courses
                }
                updateUser(user)
                    .then(res => {
                        console.log(res)
                        setStatus(3)
                    })
                    .catch(error => {
                        console.log(error)
                        setStatus(4)
                    });
            } else {
                alert("Favor de ingresar un email válido")
            }
        } else {
            alert("Favor de completar todos los campos")
        }

    }


    if (localStorage.getItem("admin")) {
        return (
            <div className="profile">
                <TopBar title="Mi Perfil" />

                <section className="content">
                    <Sidebar selected={4} />
                    <main>
                        <div className="top">
                            <h1 className="screen-title">Mi Perfil</h1>
                        </div>

                        {
                            name ? (
                                <div className="container">

                                {/* 
                                    <section className="section">
                                    <h2 className="section-title">Foto de perfil</h2>
                                    <div className="section-content">
                                        <div className="inputRow row-100">
                                            <div className="image-container">
                                                    <img onClick={changePhoto} src={photo.length > 0 ? photo : "https://firebasestorage.googleapis.com/v0/b/coemle.appspot.com/o/users%2Fprofile.svg?alt=media&token=62b00c35-8659-4dae-bdae-82f68379bd9d"} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                */}

                                    <section className="section">
                                        <h2 className="section-title">Información general</h2>
                                        <div className="section-content">

                                            <div className="inputRow row-50">
                                                <label>Nombre</label>
                                                <input type="text" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Apellidos</label>
                                                <input type="text" placeholder="" value={lastName} onChange={e => setLastName(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Celular</label>
                                                <input type="text" placeholder="" value={phone} onChange={e => setPhone(e.target.value)} />
                                            </div>

                                        </div>
                                    </section>

                                    <section className="section">
                                        <h2 className="section-title">Cuenta de usuario</h2>
                                        <div className="section-content">

                                            <div className="inputRow row-50">
                                                <label>Email</label>
                                                <input type="text" placeholder="" value={email} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div className="inputRow row-50">
                                                <label>Password</label>
                                                <input type="text" placeholder="" value={password} onChange={e => setPassword(e.target.value)} maxLength="16" />
                                            </div>

                                        </div>
                                    </section>

                                    <button onClick={update} className="btn-actualizar-user"> {status === 1 ? "Actualizar" : status === 2 ? "Guardando" : status === 3 ? "Listo!" : status === 4 ? "Error :(" : null} </button>

                                </div>
                            ) : <Loading />
                        }

                    </main>
                </section>

                {
                    showLoading ? (
                        <LoadingDialog close={close} />
                    ) : null
                }

            </div>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}

export default AdminProfile;