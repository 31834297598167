import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import oxxoPayButton from "./../../../assets/icons/oxxoPayButton.svg";
import coemleLogo from "./../../../assets/coemle.png";

import "./Styles/Dialog.scss"
import check from "./../../../assets/icons/check-cloud.svg";
import { Link } from 'react-router-dom';

const UserDialog = ({user, close}) => {
    const [userCourses, setUserCourses] = useState([])

    useEffect(()=> {
        setUserCourses(user.courses ? Object.values(user.courses) : [])
    }, []);


    return(
        <div className="userDialog">
            <div className="dialog-container">
                <span className="close-icon" onClick={close} >X</span>
                <h2>Detalles de usuario</h2>

                <div className="row row-50">
                    <span>Nombre</span>
                    <input value={user.name} readOnly/>
                </div>
                <div className="row row-50">
                    <span>Apellidos</span>
                    <input value={user.lastName} readOnly/>
                </div>
                <div className="row row-50">
                    <span>Celular</span>
                    <input value={user.phone} readOnly/>
                </div>
                <div className="row row-50">
                    <span>Email</span>
                    <input value={user.email} readOnly />
                </div>
                <div className="row row-100 row-cursos">
                    <span>Cursos</span>
                    {
                        user ? (
                            userCourses.length > 0 ? (
                                userCourses.map( c => <span className="curso" key={c.idCourse}><img src={check}/> {c.name}</span> )
                            ) : <p>Sin cursos</p>
                        ) : <p>...</p>
                    }
                </div>

            </div>
        </div>
    )
}

export const LoadingDialog = ({close}) => {
    return(
        <div className="loadingDialog">
            <div className="dialog-container">
                <span className="close-icon" onClick={close} >X</span>
                <h2>En proceso...</h2>
                <span className="icon"><FontAwesomeIcon icon={faSpinner} spin size={"4x"} /></span>
            </div>
        </div>
    )
}

export const LoginDialog = ({title, icon, text}) => {
    return (
        <div className="loadingDialog">
            <div className="dialog-container">
                <h2>{title}</h2>
                <p>{text}</p>
                {
                    icon === "loading" ? (
                        <span className="icon"><FontAwesomeIcon icon={faSpinner} spin size={"4x"} /></span>
                    ) : 
                    icon === "success" ? (
                        <span className="icon"><FontAwesomeIcon icon={faCheck} color="#2D95FD" size={"4x"} /></span>
                    ) : 
                    icon === "error" ? (
                        <span className="icon"><FontAwesomeIcon icon={faTimes} color="red" size={"4x"} /></span>
                    ) : null
                }
            </div>
        </div>
    )
}

export const SuccessDialog = ({ url }) => {
    return (
        <div className="successDialog">
            <div className="dialog-container">
                <h2>Listo!</h2>
                <p>Te enrolaste exitosamente en el curso</p>
                <span className="icon"><FontAwesomeIcon icon={faCheck} size={"4x"} /></span>
                <Link to={url} className="btn-aceptar" >Aceptar</Link>
            </div>
        </div>
    )
}

//<h2> <span style={{ color: "gray" }}>Oxxo</span> <span style={{ color:"#265681", fontStyle:"italic"}}>PAY</span> </h2>

export const OxxoDialog = ({title, text, icon, action, close}) => {

    let [mode, setMode] = useState(1);

    return(
        <div className="oxxo-dialog">
            
            {
                mode === 1 ?
                    (
                        <div className="dialog-container">

                        {
                            action.type === "generate" ? <img src={oxxoPayButton} style={{ width: "140px", marginBottom: "28px" }} /> : <h2>{title}</h2>
                        }
                        <p> {text}</p>
                        <span className="close-icon" onClick={close} >X</span>
                        {
                            icon === "loading" ? (
                                <span className="icon"><FontAwesomeIcon icon={faSpinner} spin size={"4x"} /></span>
                            ) :
                                icon === "success" ? (
                                    <span className="icon"><FontAwesomeIcon icon={faCheck} color="#2D95FD" size={"4x"} /></span>
                                ) :
                                    icon === "error" ? (
                                        <span className="icon"><FontAwesomeIcon icon={faTimes} color="red" size={"4x"} /></span>
                                    ) : null
                        }
                        {
                            action.type === "generate" ? (
                                <button onClick={action.handler} className="btn-generate" >Generar Referencia</button>
                            ) :
                                action.type === "print" ? (
                                    <button onClick={()=> setMode(2)} className="btn-generate" >Imprimir</button>
                                ) : null
                        }

                    </div>
                    ) :
                    (
                        <div className="dialog-container-reference">
                            <div className="head">
                                <img src={oxxoPayButton} />
                                <img src={coemleLogo} className="coemle-logo" />
                            </div>
                            <h1>Ficha digital de pago</h1>

                            <div className="field">
                                <p>Monto a pagar</p>
                                <div className="amount">
                                    <span className="price">$ {action.reference.amount / 100}.00</span>
                                    <span className="currency">MXN</span>
                                </div>
                                <span className="message">Oxxo cobrará una comisión adicional al momento de realizar el pago</span>
                            </div>

                            <div className="field">
                                <p>Referencia</p>
                                <div className="reference">
                                    <span className="reference-number">{action.reference.charges.data[0].payment_method.reference}</span>
                                </div>
                            </div>

                            <div className="field">
                                <p>Instrucciones</p>
                                <div className="instructions">
                                    <span className="instruction">1. Acude a la tienda OXXO más cercana.</span>
                                    <span className="instruction">2. Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</span>
                                    <span className="instruction">3. Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</span>
                                    <span className="instruction">4. Realiza el pago correspondiente con dinero en efectivo.</span>
                                    <span className="instruction">5. Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</span>
                                </div>
                            </div>

                            <div className="fin">
                                <span>Al pagar la referencia, el curso será añadido automáticamente a tu perfil</span>
                                <button onClick={close}>Ok! Cerrar</button>
                            </div>

                        </div>
                    )
            }


        </div>
    )
}


export const ShowReference = (order) => {
    return(
        <div className="showReference">
            <p>Amount {order.charges.data[0].amount}</p>
        </div>
    )
}

export default UserDialog