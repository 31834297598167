export const createOxxoReference = (data) => {
    return new Promise((resolve, reject) => {
        var url = "https://capacitacion.coemle.org/api/payment/conekta/createOrder";
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                resolve(response)
        })
    })
}


export default createOxxoReference;
