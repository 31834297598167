import React, {useState, useEffect} from 'react';
import { Redirect, Link } from 'react-router-dom';
import TopBar from "./../TopBar";
import Sidebar from "./Sidebar";
import coemlelogo from "./../../../assets/coemle.png";
import photo from "./../../../assets/img/cover-user-dashboard.jpeg"

import "./Styles/UserDashboard.scss"
import Loading from '../Loading';

import {getActiveCourses} from "./../../../Controller/CoursesController";

const UserDashboard = () => {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        getActiveCourses()
            .then(result => {
                if (result.courses) {
                    setCourses(result.courses)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, []);


    if(localStorage.getItem("user")){
        return (
            <div className="user-dashboard">
                <TopBar title="Inicio" />

                <section className="content">
                    <Sidebar selected={1} /> 
                        {
                            courses.length > 0 ? (
                            <main className="user-dashboard-main">
                                <div className="cover" style={{ backgroundImage: `url(${photo})` }} >
                                    <div className="cover-content">
                                        <div className="cover-desc">
                                            <span>CENTRO DE APRENDIZAJE</span>
                                        </div>
                                        <h1 className="cover-title">MANTENTE ACTUALIZADO</h1>
                                    </div>
                                    <img alt="" src={coemlelogo} />
                                </div>



                                <div className="container">
                                    <h2 className="screen-title">Explorar cursos</h2>
                                    <div className="cursos">
                                        {
                                            courses.map( c => 
                                                <Link to={"/user/viewCourse?course=" + c.id} className="curso" key={c.id}>
                                                    <div className="curso-container">
                                                        <div className="present" style={{ backgroundImage: `url(${c.photo})` }} >
                                                            <span>ESCUELA DE</span>
                                                            <p>{c.category}</p>
                                                        </div>
                                                        <div className="info">
                                                            <h3>{c.name}</h3>
                                                            <span className="price">${c.price}</span>
                                                        </div>
                                                    </div>
                                                </Link>        
                                            )
                                        }
                                    </div>
                                </div>

                            </main>
                            ) : <Loading />
                        }
                </section>

            </div>
        )
    } else {
        return(
            <Redirect to="/" />
        )
    }
}

export default UserDashboard;