import React, {useEffect, useState} from 'react';
import {Redirect, Link} from "react-router-dom";

import { getAllCourses, updateStatus} from "./../../../Controller/CoursesController";

import "./Styles/AdminCourses.scss";
import Sidebar from './Sidebar';
import TopBar from '../TopBar';
import Loading from '../Loading';

const AdminCourses = () => {

    const [courses, setCourses] = useState("");
    const [update, setUpdate] = useState(false)

    useEffect(()=> {
        getAllCourses()
            .then(result => {
                if(result.courses) {
                    setCourses(result.courses)
                }
            })
            .catch(error => {
                console.log(error)
            })
    },[]);

    useEffect(() => {
        getAllCourses()
            .then(result => {
                if (result.courses) {
                    setCourses(result.courses)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [update]);

    const setStat = (id, stat) => {
        updateStatus(id, stat)
            .then(res => {
                console.log(res)
                setUpdate(!update)
            })
            .catch(err => console.log(err))
    }

    if (localStorage.getItem("admin")) {
        return (
            <div className="adminCourses">
                <TopBar title="Cursos" />

                <section className="content">
                    <Sidebar selected={2} />
                    <main>
                        <div className="top">
                            <h1 className="screen-title">Cursos</h1>
                            <Link to="/admin/newCourse" className="button"> Nuevo > </Link>
                        </div>
                        <div className="container">

                            

                            {
                                courses ? (
                                    courses.length > 0 ? (
                                        courses.map( course => 
                                            <div className="course" key={course.id}>
                                                <div className="course-img">
                                                    <img alt="" src={course.photo} />
                                                </div>
                                                <div className="course-title">
                                                    <span>{course.name}</span>
                                                </div>
                                                <div className="course-price">
                                                    <span>${course.price}</span>
                                                </div>
                                                <div className="course-duration">
                                                    <span>{course.duration}</span>
                                                </div>
                                                <div className="course-actions">
                                                    <Link to={"/admin/editCourse?course="+course.id} style={{textDecoration:"none"}} ><span>Editar</span></Link>
                                                    {course.status ? <span className="action" onClick={() => setStat(course.id, false)} >Desactivar</span> : <span className="action" style={{ color: "green" }} onClick={() => setStat(course.id, true)} >Activar</span>}
                                                    <Link to={"/admin/viewCourse?course=" + course.id} style={{ textDecoration: "none" }} ><span>Ver</span></Link>
                                                </div>
                                            </div>
                                        )  
                                    ) : <Loading />
                                ) : <Loading />
                            }

                        </div>
                    </main>
                </section>

            </div>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}

export default AdminCourses;