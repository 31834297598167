import React, {useState, useEffect} from 'react';
import { Redirect, Link } from 'react-router-dom';

import "./Styles/UserCourses.scss";
import Sidebar from './Sidebar';
import TopBar from '../TopBar';
import { LoadingDialog } from "./Dialog";
import Loading from '../Loading';

const UserCourses = () => {

    let [courses, setCourses] = useState([])

    useEffect(()=> {
        let user = JSON.parse(localStorage.getItem("user"))
        if(user.courses) {
            setCourses(Object.values(user.courses))
        } else {
            console.log("No hay cursos")
        }
    }, [])
    

    if(localStorage.getItem("user")) {
        return (
            <div className="userCourses">
                <TopBar title="Aprender" />

                <section className="content">
                    <Sidebar selected={2} />

                    <main>
                        <div className="top">
                            <h1 className="screen-title">Mis cursos</h1>
                        </div>

                        <div className="container">
                            <div className="cursos-user">
                                {
                                    courses.length > 0 ? (
                                        courses.map(c =>
                                            <Link to={"/user/takeCourse?course=" + c.idCourse} className="curso-user" key={c.idCourse}>
                                                <div className="curso-container-user">
                                                    <div className="present" style={{ backgroundImage: `url(${c.photoCourse})` }} >
                                                        <span>APRENDE CON</span>
                                                        <p>COEMLE</p>
                                                    </div>
                                                    <div className="info">
                                                        <h3>{c.name}</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    ) : <p>Aún no estás inscrito a ningún curso</p>
                                }
                            </div>
                        </div>

                    </main>

                </section>

            </div>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }


};

export default UserCourses;