import React, {useEffect, useState} from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { getNumCourses} from "./../../../Controller/CoursesController";
import { getNumUsers } from "./../../../Controller/UsersController";

import "./Styles/Dashboard.scss";

import TopBar from "./../TopBar";
import Sidebar from "./Sidebar";

const AdminDashboard = () => {

    const [numCourses, setNumCourses] = useState()
    const [numUsers, setNumUsers] = useState()

    useEffect( () => {
        getNumCourses()
            .then(response => {
                getNumUsers()
                    .then(res => {
                        setNumUsers(res.users)
                        setNumCourses(response.courses)
                    })
            })
    }, [] )




    if (localStorage.getItem("admin")) {
        return (
            <div className="adminDashboard">
                <TopBar title="Inicio"/>

                <section className="content">
                    <Sidebar selected={1}/>
                    <main>
                        <h1 className="screen-title">Dashboard</h1>
                        <div className="container">
                            
                            <div className="dashboard-card">
                                <span className="card-title">Total de usuarios</span>
                                <span className="card-text"> {numUsers ? numUsers : <FontAwesomeIcon icon={faSpinner} spin size={"1x"} /> } </span>
                                <Link to="/admin/users" className="card-button" > ver usuarios </Link>
                            </div>

                            <div className="dashboard-card">
                                <span className="card-title">Total de cursos</span>
                                <span className="card-text"> {numCourses ? numCourses : <FontAwesomeIcon icon={faSpinner} spin size={"1x"} />} </span>
                                <Link to="/admin/courses" className="card-button" > ver cursos </Link>
                            </div>


                        </div>
                    </main>
                </section>

                

            </div>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}

export default AdminDashboard;