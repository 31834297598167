import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import coemlelogo from "./../../assets/coemle.png";
import photo from "./../../assets/img/cover-user-dashboard.jpeg"

import "./Courses.scss"
import Loading from './../React/Loading';

import { getActiveCourses } from "./../../Controller/CoursesController";
import Nav from '../React/Nav';

const Courses = () => {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        getActiveCourses()
            .then(result => {
                if (result.courses) {
                    setCourses(result.courses)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, []);



        return (
            <div className="web-courses">
                <Nav />

                <section className="content">
                    {
                        courses.length > 0 ? (
                            <main className="web-courses-main">
                                <div className="cover" style={{ backgroundImage: `url(${photo})` }} >
                                    <div className="cover-content">
                                        <div className="cover-desc">
                                            <span>CENTRO DE APRENDIZAJE</span>
                                        </div>
                                        <h1 className="cover-title">MANTENTE ACTUALIZADO</h1>
                                    </div>
                                    <img alt="" src={coemlelogo} />
                                </div>



                                <div className="container">
                                    <h2 className="screen-title">Explorar cursos de COEMLE</h2>
                                    <div className="cursos">
                                        {
                                            courses.map(c =>
                                                <Link to={"/curso?course=" + c.id} className="curso" key={c.id}>
                                                    <div className="curso-container">
                                                        <div className="present" style={{ backgroundImage: `url(${c.photo})` }} >
                                                            <span>ESCUELA DE</span>
                                                            <p>{c.category}</p>
                                                        </div>
                                                        <div className="info">
                                                            <h3>{c.name}</h3>
                                                            <span className="price">${c.price}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    </div>
                                </div>

                            </main>
                        ) : <div style={{marginTop:"40px", display:"flex", justifyContent:"center", width:"100%"}} > 
                                <Loading />
                            </div>
                    }
                </section>

            </div>
        )
}

export default Courses;