import React from 'react';

import { Link } from "react-router-dom";

import "./Styles/Sidebar.scss";
import menu from "./../../../assets/icons/menu-dots.svg";
import coursesIcon from "./../../../assets/icons/courses.svg";
import usersIcon from "./../../../assets/icons/profile.svg";

const Sidebar = ({selected}) => {

    let user = JSON.parse(localStorage.getItem("admin"));

    return(
        <div className="sidebar">
            <ul>
                <Link to="/admin/dashboard"> <li className={selected === 1 ? "selected" : null} > <img alt="" src={menu}/> <span>Inicio</span> </li> </Link>
                <Link to="/admin/courses"> <li className={selected === 2 ? "selected" : null}> <img alt="" src={coursesIcon} /> <span>Cursos</span> </li> </Link>
                <Link to="/admin/users"> <li className={selected === 3 ? "selected" : null}> <img alt="" src={usersIcon} /> <span>Usuarios</span> </li> </Link>
                <Link to="/admin/profile"> <li className={selected === 4 ? "selected" : null}>  <div className="user-photo"> <img alt="" src={user.photo ? user.photo : usersIcon} />  </div> <span>Perfil</span> </li> </Link>
            </ul>
        </div>
    )
}

export default Sidebar;