import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Log from "./Componentes/React/Log";
import UserDashboard from "./Componentes/React/User/UserDashboard";
import AdminDashboard from "./Componentes/React/Admin/AdminDashboard";
import AdminCourses from './Componentes/React/Admin/AdminCourses';
import AdminEditCourse from './Componentes/React/Admin/AdminEditCourse';
import "./App.css";
import AdminNewCourse from './Componentes/React/Admin/AdminNewCourse';
import AdminViewCourse from './Componentes/React/Admin/AdminViewCourse';
import AdminUsers from './Componentes/React/Admin/AdminUsers';
import AdminProfile from './Componentes/React/Admin/AdminProfile';
import UserViewCourse from './Componentes/React/User/ViewCourse';
import UserProfile from './Componentes/React/User/UserProfile';
import UserCourses from './Componentes/React/User/UserCourses';
import TakeCourse from './Componentes/React/User/TakeCourse';
import UserPay from './Componentes/React/User/UserPay';
import Courses from './Componentes/Web/Courses';
import Curso from './Componentes/Web/Curso';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact render={() => <Log />} />
        
        <Route path="/cursos" render={()=> <Courses />} />
        <Route path="/curso" render={() => <Curso />} />

        <Route path="/user" render={() => <UserDashboard />}>
          <Route path="/user/dashboard" render={() => <UserDashboard />} />
          <Route path="/user/viewCourse" render={() => <UserViewCourse />} />
          <Route path="/user/profile" render={() => <UserProfile />} />
          <Route path="/user/courses" render={() => <UserCourses />} />
          <Route path="/user/takeCourse" render={() => <TakeCourse />} />
          <Route path="/user/pay" render={() => <UserPay />} />
        </Route>

        <Route path="/admin" render={() => <AdminDashboard />}>
          <Route path="/admin/dashboard" render={() => <AdminDashboard />} />
          <Route path="/admin/courses" render={() => <AdminCourses />} />
          <Route path="/admin/users" render={() => <AdminUsers />} />
          <Route path="/admin/profile" render={() => <AdminProfile />} />
          <Route path="/admin/editCourse" render={() => <AdminEditCourse />} />
          <Route path="/admin/newCourse" render={() => <AdminNewCourse />} />
          <Route path="/admin/viewCourse" render={() => <AdminViewCourse />} />
        </Route>
          
      </Switch>
    </Router>
  );
}

export default App;
