/**
 * export const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
        var url = 'https://coemle.web.app/user/signIn';
        var data = { email: email, password: password };

        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                if(response.result){
                    if(response.user.role === "admin") {
                        localStorage.setItem("admin", JSON.stringify(response.user))
                    } else if (response.user.role === "user") {
                        localStorage.setItem("user", JSON.stringify(response.user))
                    }
                    resolve(response)
                } else {
                    resolve(response)
                }
            });
    })
} 
 */

export const signUp = (name, lastName, email, password, phone) => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/user/signUp2';
        var data = {
            name : name, 
            lastName : lastName,
            email : email, 
            password : password,
            phone : phone,
            photo: "https://firebasestorage.googleapis.com/v0/b/coemle.appspot.com/o/users%2Fprofile.svg?alt=media&token=62b00c35-8659-4dae-bdae-82f68379bd9d",
            role : "user",
            status : true
        }
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error) )
            .then(response => {
                resolve(response)
            });
    })
}

export const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/user/signIn2';
        var data = { email: email, password: password };

        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                if (response.status) {
                    if (response.user.role === "admin") {
                        localStorage.setItem("admin", JSON.stringify(response.user))
                    } else if (response.user.role === "user") {
                        localStorage.setItem("user", JSON.stringify(response.user))
                    }
                    resolve(response)
                } else {
                    resolve(response)
                }
            });
    })
}