export const getNumUsers = () => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/user/getNumUsers';

        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => resolve(response));
    })
}


//THIS HAS TO BE ONLY AFTER THE PAYMENT DONE
export const enrollTo = (data) => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/user/enrollCourse';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                resolve(response)
        })
    })
}

export const updateUser = (user) => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/user/update';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                if(response.status) {
                    if(user.role === "admin") {
                        localStorage.setItem("admin", JSON.stringify(user))
                    } else if(user.role === "user"){
                        localStorage.setItem("user", JSON.stringify(user))
                    }
                }
                resolve(response)
            })
    })
}

export const getAllUsers = () => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/user/getAll2';
        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => resolve(response));
    })
}

export const updateStatus = (id, stat) => {
    let data = {
        "id": id,
        "status": stat
    }
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/user/updateStatus';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                resolve(response)
            })
    })
}