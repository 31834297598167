import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { getCourseById } from "./../../../Controller/CoursesController";

import "./Styles/ViewCourse.scss"
import TopBar from '../TopBar';
import Sidebar from './Sidebar';
import coemlelogo from "./../../../assets/coemle.png";
import money from "./../../../assets/icons/money.svg";
import clock from "./../../../assets/icons/clock.svg";
import goBackIcon from "./../../../assets/icons/goBack.svg";

const UserViewCourse = () => {

    //STATE
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState("")
    const [category, setCategory] = useState("")
    const [price, setPrice] = useState("")
    const [photo, setPhoto] = useState("")
    const [topics, setTopics] = useState([])
    const [url, setURL] = useState("")
    const [id, setId] = useState("")
    const [course, setCourse] = useState([])
    const [goToPay, setGoToPay] = useState(false)

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const idCourse = params.get('course');
        getCourseById(idCourse)
            .then(result => {
                setCourse(result.course)
                setId(idCourse)
                setName(result.course.name)
                setDescription(result.course.description)
                setPrice(result.course.price)
                setURL(result.course.URLcourse)
                setDuration(result.course.duration)
                setCategory(result.course.category)
                setTopics(result.course.topics ? Object.values(result.course.topics) : [])
                setPhoto(result.course.photo)
            })
            .catch(error => console.log(error))
    }, []);

    const enroll = () => {
        sessionStorage.setItem("courseIntent", JSON.stringify(course))
        setGoToPay(true)
    }

    return (
        <div className="adminViewCourse viewCourse">
            <TopBar title={name} />
            {
                goToPay ? (
                    <Redirect to="/user/pay" />
                ) : 
                    <section className="content">

                        <Sidebar selected={1} />

                        <main>

                            <div className="cover" style={{ backgroundImage: `url(${photo})` }} >
                                <div className="cover-content">
                                    <div className="cover-category">
                                        <span>ESCUELA DE </span>
                                        <span className="category">{category}</span>
                                    </div>
                                    <h1 className="cover-title">{name}</h1>
                                </div>
                                <img alt="" src={coemlelogo} />
                                <Link to="/user/dashboard" className="goback" > <img alt="regresar" src={goBackIcon} /> Regresar</Link>
                            </div>

                            <div className="banner-general-info">
                                <div className="info">
                                    <span className="info-title"> <img alt="" src={clock} />Duración</span>
                                    <span className="info-content">{duration}</span>
                                </div>
                                <div className="info">
                                    <span className="info-title"> <img alt="" src={money} />Precio</span>
                                    <span className="info-content">{price}</span>
                                </div>
                                <div className="info">
                                    <Link className="enroll-btn" onClick={enroll} > Inscribirse </Link>
                                </div>
                            </div>

                            <div className="container">
                                <div className="course-content-info">
                                    <section>
                                        <h2>Lo que aprenderás</h2>
                                        <p>{description}</p>
                                    </section>
                                    <section>
                                        <h2>Contenido temático</h2>
                                        <div className="topics">
                                            {
                                                topics ? (
                                                    topics.map(topic =>
                                                        <div className="topic" key={Math.random()}>
                                                            <h3>{topic.title}</h3>
                                                            <p>{topic.description}</p>
                                                        </div>
                                                    )
                                                ) : <p>Cargando...</p>
                                            }
                                        </div>
                                    </section>

                                    <section className="goAction">
                                        {/*<Link to={"/user/enrollTo?course=" + id} className="enroll-btn" > Inscribirse </Link>*/}
                                        <button className="enroll-btn" onClick={enroll} >Inscribirse</button>
                                    </section>

                                </div>
                            </div>



                        </main>

                    </section>
            }
        </div>
    )


}

export default UserViewCourse;