import React, {useState} from 'react';

import "./../SCSS/Nav.scss"
import coemle from "./../../assets/coemle.png";
import menuIcon from "./../../assets/icons/menu.svg";

const Nav = () => {

    const [fullMode, setFullMode] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const collapseMenu = () => {
        setShowMenu(!showMenu);
        setFullMode(!fullMode);
    }

    return(
        <header className={fullMode ? "titlebar-100" : null}>
            <nav>
                <div className="container container-first">
                    <a href="http://www.coemle.org/"> <img alt="coemle" className="nav-image" src={coemle} /> </a>
                </div>

                <div className="container container-second">
                    <ul>
                        <li className="menu-header-el"> <a href="http://www.coemle.org/" >Inicio</a> </li>
                        <li className="menu-header-el"> <a href="http://www.coemle.org/page3.html" >Organización</a> </li>
                        <li className="menu-header-el"> <a href="http://www.coemle.org/page4.html" >Red Empresarial</a> </li>
                        <li className="menu-header-el"> <a href="http://www.coemle.org/page2.html" >Registro</a> </li>
                        <li className="menu-header-el"> <a href="/cursos" >Cursos</a> </li>
                        <li className="menu-header-el"> <a href="/" > {localStorage.getItem("user") ? "Mi Perfil" : localStorage.getItem("admin") ? "Mi perfil" : "Iniciar Sesión"}  </a> </li>
                        <li className="bars" onClick={collapseMenu} > <img alt="" src={menuIcon} className="menu-header-bars" /> </li >
                    </ul>
                </div>

                <menu className={"titlebar-menu"} style={{ display: showMenu ? "flex" : "none" }}>
                    <div className="container-menu">
                        <ul>
                            <li className="menu-header-el"> <a href="http://www.coemle.org/" >Inicio</a> </li>
                            <li className="menu-header-el"> <a href="http://www.coemle.org/page3.html" >Organización</a> </li>
                            <li className="menu-header-el"> <a href="http://www.coemle.org/page4.html" >Red Empresarial</a> </li>
                            <li className="menu-header-el"> <a href="http://www.coemle.org/page2.html" >Registro</a> </li>
                            <li className="menu-header-el"> <a href="http://www.coemle.org/cursos/" >Cursos</a> </li>
                            <li className="menu-header-el"> <a href="http://www.coemle.org/learn" >Iniciar Sesión</a> </li>
                        </ul>
                    </div>
                </menu>

            </nav>
        </header>
    )
}

export default Nav;