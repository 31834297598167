import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import "./../SCSS/Loading.scss"

const Loading = () => {
    return(
        <div className="loading">
            <h1>Cargando</h1>
            <span><FontAwesomeIcon icon={faSpinner} spin size={"3x"} /></span>
            <span style={{marginTop:"18px", color:"gray"}}>(Si tarda demasiado, prueba recargando la página)</span>
        </div>
    )
}

export default Loading;