import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";

import { getAllUsers} from "../../../Controller/UsersController";

import "./Styles/AdminUsers.scss";
import Sidebar from './Sidebar';
import TopBar from '../TopBar';
import UserDialog from './Dialog';
import Loading from '../Loading';

const AdminUsers = () => {

    const [users, setUsers] = useState("");
    const [update, setUpdate] = useState(false);
    const [showDialog, setShowDialog] = useState(false)
    let [userToShow, setUserToShow] = useState("")

    useEffect(()=> {
        getAllUsers()
            .then(result => {
                if(result.users) {
                    setUsers(result.users)
                }
            })
            .catch(error => {
                console.log(error)
            })
    },[]);

    useEffect(() => {
        getAllUsers()
            .then(result => {
                if (result.users) {
                    setUsers(result.users)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [update]);


    const showUser = (user) => {
        setUserToShow(user)
        setShowDialog(true)
        console.log(user)
    }

    const close = () => {
        setShowDialog(false)
    }

    if (localStorage.getItem("admin")) {
        return (
            <div className="adminUsers">
                <TopBar title="Usuarios" />

                <section className="content">
                    <Sidebar selected={3} />
                    <main>
                        <div className="top">
                            <h1 className="screen-title">Usuarios</h1>
                        </div>
                        <div className="container">

                            

                            {
                                users ? (
                                    users.length > 0 ? (
                                        users.map( user => 
                                            <div className="course" key={user.id}>
                                                <div className="course-img">
                                                    <img alt="" src={user.photo} />
                                                </div>
                                                <div className="course-title">
                                                    <span>{user.name + " " + user.lastName}</span>
                                                </div>
                                                <div className="course-price">
                                                    <span>{user.phone}</span>
                                                </div>
                                                <div className="course-duration">
                                                    <span>{user.email}</span>
                                                </div>
                                                <div className="course-actions">
                                                    <button onClick={ ()=> showUser(user) } ><span>Ver</span></button>
                                                </div>
                                                
                                            </div>
                                        )  
                                    ) : <Loading />
                                ) : <Loading />
                            }

                        </div>
                    </main>
                </section>

                {
                    showDialog ? (
                        <UserDialog user={userToShow} close={close} />
                    ) : null
                }

            </div>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}

export default AdminUsers;