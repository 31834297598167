import React from 'react';

import { Link } from "react-router-dom";

import "./Styles/Sidebar.scss";
import menu from "./../../../assets/icons/menu-dots.svg";
import coursesIcon from "./../../../assets/icons/courses.svg";
import usersIcon from "./../../../assets/icons/profile.svg";

const Sidebar = ({selected}) => {

    let user = JSON.parse(localStorage.getItem("user"));

    return(
        <div className="sidebar">
            <ul>
                <Link to="/user/dashboard"> <li className={selected === 1 ? "selected" : null} > <img alt="" src={menu}/> <span>Inicio</span> </li> </Link>
                <Link to="/user/courses"> <li className={selected === 2 ? "selected" : null}> <img alt="" src={coursesIcon} /> <span>Aprender</span> </li> </Link>
                <Link to="/user/profile"> <li className={selected === 4 ? "selected" : null}>  <div className="user-photo"> <img alt="" src={user.photo ? user.photo : usersIcon} />  </div> <span>Perfil</span> </li> </Link>
            </ul>
        </div>
    )
}

export default Sidebar;