import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import "./Styles/Dialog.scss"
import check from "./../../../assets/icons/check-cloud.svg";

const UserDialog = ({user, close}) => {
    const [userCourses, setUserCourses] = useState([])

    useEffect(()=> {
        setUserCourses(user.courses ? Object.values(user.courses) : [])
    }, []);


    return(
        <div className="userDialog">
            <div className="dialog-container">
                <span className="close-icon" onClick={close} >X</span>
                <h2>Detalles de usuario</h2>

                <div className="row row-50">
                    <span>Nombre</span>
                    <input value={user.name} readOnly/>
                </div>
                <div className="row row-50">
                    <span>Apellidos</span>
                    <input value={user.lastName} readOnly/>
                </div>
                <div className="row row-50">
                    <span>Celular</span>
                    <input value={user.phone} readOnly/>
                </div>
                <div className="row row-50">
                    <span>Email</span>
                    <input value={user.email} readOnly />
                </div>
                <div className="row row-100 row-cursos">
                    <span>Cursos</span>
                    {
                        user ? (
                            userCourses.length > 0 ? (
                                userCourses.map( c => <span className="curso" key={c.idCourse}><img src={check}/> {c.name}</span> )
                            ) : <p>Sin cursos</p>
                        ) : <p>...</p>
                    }
                </div>

            </div>
        </div>
    )
}

export const LoadingDialog = ({close}) => {
    return(
        <div className="loadingDialog">
            <div className="dialog-container">
                <span className="close-icon" onClick={close} >X</span>
                <h2>En proceso...</h2>
                <span className="icon"><FontAwesomeIcon icon={faSpinner} spin size={"4x"} /></span>
            </div>
        </div>
    )
}

export default UserDialog