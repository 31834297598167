import React, {useState} from 'react';
import {Link, Redirect} from "react-router-dom"

import coemle from "./../../assets/coemle.png";
import logout from "./../../assets/icons/logout.svg";
import "./../SCSS/TopBar.scss";

const TopBar = ({title}) => {

    const [logOut, setLogOut] = useState(false)

    const salir = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("admin");
        setLogOut(true)
    }

    if(logOut){
        return(
            <Redirect to="/" />
        )
    } else {
        return (
            <div className="topbar">
                <Link to="/"><img alt="coemle" src={coemle} /></Link>
                <span>{title}</span>
                <div className="logOption" onClick={salir}>
                    <img className="svg" alt="salir" src={logout} />
                Salir
            </div>
            </div>
        )
    }
}

export default TopBar;