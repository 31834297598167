export const updateStatus = (id, stat) => {
    let data = {
        "id" : id,
        "status" : stat
    }
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/course/updateStatus';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                resolve(response)
            })
    })
}

export const getNumCourses = () => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/course/getNum';

        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => resolve(response));
    })
}

export const getActiveCourses = () => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/course/getActiveCourses';

        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => resolve(response));
    })
}

export const getAllCourses = () => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/course/getAll2';

        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => resolve(response));
    })
}

export const getCourseById = (id) => {
    return new Promise((resolve, reject) => {
        var url = `https://capacitacion.coemle.org/api/course/getCourseById2?id=${id}`;
        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => resolve(response));
    })
}

export const updateCourse = (course) => {
    return new Promise ((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/course/update';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(course),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                resolve(response)
            })
    })
}

export const newCourse = (course) => {
    return new Promise((resolve, reject) => {
        var url = 'https://capacitacion.coemle.org/api/course/new';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(course),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => reject(error))
            .then(response => {
                resolve(response)
            })
    })
}
